export default {
  app_ame: "FiberxForm",
  locale: "en",
  locales: { en: "EN" },
  githubAuth: null,
  notion: { worker: "https://notion-forms-worker.notionforms.workers.dev/v1" },
  links: {
    help_url: "",
    helpdesk_sitemap_url: "",
    github_url: "",
    github_forum_url: "",
    discord: "",
    twitter: "",
    zapier_integration:
      "https://zapier.com/developer/public-invite/146950/58db583730cc46b821614468d94c35de/",
    book_onboarding: "",
    feature_requests: "",
    changelog_url: "",
    roadmap: "",
  },
}
